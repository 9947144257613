// Global CSS variables
:root {
  --height-menu-bar: 54px;
  
  --Black: #000000;
  --Blue2: #1d212b;
  --Blue3: #242936;
  --Blue3Bis: #2e3546;
  --Blue4: #3b4459;
  --Blue5: #566180;
  --Blue6: #12131c;
  --Blue7: #848da6;
  --PrimaryBlue7: #d2d7e3;
  --PrimaryBlue8: #eceff6;
  --Blue: #2566e2;
  --Bluedarker: #1a4ba9;
  --Bluewriting: #6ca0ff;
  --Green: #00bfa5;
  --GreenLight: #559a92;
  --GreenDisable: #0c7568;
  --GreenDarker: #0d8274;
  --DarkGrey: #181919;
  --Grey1: #313232;
  --Grey2: #636465;
  --Grey3: #949597;
  --Grey4: #c6c7c9;
  --Grey5: #dee0e1;
  --Grey6: #eaecee;
  --Grey3Bis: #b7b7b7;
  --GreyDisable: #989aa0;
  --Orange: #fa6400;
  --Red: #ff3455;
  --Red2: #ff1744;
  --RedDarker: #c3253f;
  --White: #ffffff;
  --Yellow: #ffdd00;
  --Yellow2: #ffd625;
  --YellowDarker: #edbd00;
}

*, *::before, *::after {
  box-sizing: content-box !important;
}

#root,
body,
html {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  background-color: var(--Blue2);
}

div,
button {
  outline: none;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
}

::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    outline: 0px;
 }
