.user-menu-button {
    margin-left: 20px;
    color: var(--White);
    display: grid;
    grid-template-columns: auto 21px;
    align-items: center;
    padding: 0px 10px 0px 8px;
}

.user-menu-button label {
    padding: 2px 0px 0px 8px;
    margin: 0;
    line-height: 32px;
    vertical-align: top;
    cursor: pointer;
    /* The next two properties exist only to override the values set in the measures CSS. A future refactor should handle this. */
    font-size: 16px;
}

#user-menu-icon-user-expand {
    width: 16px;
    height: 16px;
    margin: 0px 0px 3px 4px;
    font-size: 16px;
}

.option {
    height: 36px;
    border-radius: 2px;
    position: relative;
    text-align: center;
    vertical-align: middle;
}

.option.active {
    background-color: var(--Blue);
}

.option.inactive.disabled {
    opacity: 0.5;
}

.option-usermenu.active {
    background-color: var(--Blue);
}

.option:hover:not(.active) {
    background-color: var(--Blue4);
}

.user-menu-switch {
    border-radius: 50%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.user-menu {
    font-weight: 400;
    width: 181px;
    margin-top: calc(var(--height-menu-bar) - 8px);
    margin-right: 18px;
    position: absolute;
    border-radius: 3px;
    box-shadow: 0 0 10px 0 #000;
    background-color: var(--Blue3Bis);
    border: 1px solid #424242;
}

.arrow-up {
    width: 0; 
    height: 0; 
    position: absolute;
    margin:-6px 161px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--Blue4);
}

.user-menu-link {
    height: 29px;
    padding: 0px;
    font-size: 14px;
    text-align: left;
    display: grid;
    grid-template-columns: 36px auto;
    align-items: center;
}

.user-menu-link:hover {
    background-color: var(--Blue4);
    color: var(--White);
    text-decoration: none;
}

.user-menu-link .update-button {
    background: var(--Blue);
    width: 51px;
    height: 15px;
    border: none;
    font-size: 10px;
    border-radius: 9px;
}

.user-menu-about {
    display: grid;
    grid-template-columns: auto auto;
}

.user-menu-icon {
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 20px;
    margin-top: -4px;
}

.user-menu-seperator{
    height: 1px;
    background-color:var(--Black);
    opacity: 0.3;
}

a.hyperlink-user-menu:link{
    color: var(--White);
    text-decoration: none;
}

a.hyperlink-user-menu:visited{
    color: var(--White);
    text-decoration: none;
}

a.hyperlink-user-menu:active{
    color: var(--White);
    text-decoration: none;
}
