.selection-grid {
  width: fit-content;
  height: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

@media(max-width: 1440px) {
  .selection-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media(max-width: 1280px) {
  .selection-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media(max-width: 900px) {
  .selection-grid {
    grid-template-columns: 1fr;
  }
}

.selection-grid .item {
  margin: 25px ;
  border: solid 2px var(--Blue2);
}

.selection-grid .item-clicked {
  margin: 25px;
  border: solid 2px var(--Bluewriting);
}

.item a {
  display: inline-block;
  text-align: center;
}

a:-webkit-any-link {
  text-decoration: none;
}

.item img,
.item-clicked img {
  height: auto;
}

.icon-square {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 190px;
  width: 250px;
  background: var(--Blue3Bis);
}

.icon-group-square {
  position: relative;
  top: 0;
  left: 0;
  min-height: 190px;
  width: 250px;
  background: var(--Blue3Bis);
}

.icon-group-square:hover,
.icon-square:hover {
  background-color: var(--Blue4);
}

.beta-icon {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  background: none;
}

.beta-icon + .app-icon {
  z-index: 1;
  position: absolute;
  top: 40px;
  left: 60px;
  background: none;
}

.app-description {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  min-height: 60px;
  width: 250px;
  background: var(--Blue3);
  color: var(--White);
}
