.landing-page {
  height: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  letter-spacing: 0.25px;
  line-height: 1.5;
  color: var(--White);
  background-color: var(--Blue2);
}

.landing-page-header {
  width: 100%;
  height: 30px;
  font-size: 32px;
  padding-top:50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-page-body {
  width: 73%;
  justify-content: center;
  align-items: center;
  display: inline-block;
}

.logo-ecosystem-color {
  height: 100%;
  width: 250px;
  padding: 20px;
}

.landing-page-title {
  color: var(--Bluewriting);
  white-space: nowrap;
}

.selection-header {
  font-size: 28px;
  padding: 50px 0px 25px 0px;
}