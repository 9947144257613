.menu {
  height: var(--height-menu-bar);
  padding: 0px 33px 0px 33px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  color: var(--White);
  background-color: var(--Blue2);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}